.category-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto; /* Make modal scrollable */
    padding: 20px 0; /* Add padding for top and bottom margins */
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 40px 20px !important;
    border-radius: 10px;
    width: 800px !important; /* Set width to 2000px and add !important */
    max-width: 90%; /* Ensure it doesn't exceed 90% of the viewport */
    max-height: 90%; /* Limit the height to avoid going off-screen */
    overflow-y: auto; /* Enable vertical scrolling */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .modal-content h2 {
    margin-top: 0;
    font-size: 24px;
    color: #333;
    margin-bottom: 30px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
  }
  
  .form-group input[type="text"],
  .form-group input[type="file"],
  .form-group textarea,
  .form-group select {
    width: calc(100% - 22px);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    color: #333;
  }
  
  .form-group textarea {
    resize: vertical;
    height: 80px;
  }
  
  .form-actions {
    text-align: right;
  }
  
  .form-actions button {
    padding: 10px 20px;
    margin-left: 10px;
    background-color: #500523;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .form-actions button:hover {
    background-color: #7a0831;
  }
  
  .form-actions button:first-child {
    background-color: #ccc;
    color: #333;
  }
  
  .form-actions button:first-child:hover {
    background-color: #bbb;
  }
  
  .loader {
    margin-bottom: 15px;
    text-align: center;
    font-size: 16px;
    color: #500523;
  }
  