.side-menu {
  width: 250px;
  height: 100vh;  /* Full viewport height */
  background-color: #f0f0f0;
  padding: 80px 10px;
  display: flex;
  flex-direction: column;
  position: fixed;
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
  color: #333;
}

.menu-item:hover {
  background-color: #ddd;
}

.menu-icon {
  margin-right: 10px;
  font-size: 20px;
  color: #333;
}

.active {
  background-color: #ddd;
}
