.container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .heading {
    text-align: center;
    color: #500523;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .label {
    margin-bottom: 8px;
    font-weight: 500;
    color: #333;
  }
  
  .searchInput, .select, .textarea, .sendButton {
    font-size: 16px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    margin-bottom: 15px;
  }
  
  .searchInput {
    width: 100%;
  }
  
  .select {
    width: 100%;
    background-color: #fff;
  }
  
  .textarea {
    width: 100%;
    resize: vertical;
  }
  
  .sendButton {
    background-color: #500523;
    color: #fff;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .sendButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .sendButton:hover:enabled {
    background-color: #770834;
  }
  
  .loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .imageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
  }
  
  .imagePreview {
    margin-top: 10px;
    width: 100%;
    max-width: 200px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .uploadLabel {
    font-weight: 500;
    color: #333;
    margin-bottom: 8px;
  }
  
  .uploadButton {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #500523;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.3s, transform 0.2s;
    text-align: center;
    margin-top: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .uploadButton:hover {
    background-color: #770834;
    transform: translateY(-2px);
  }
  
  .uploadButton:active {
    background-color: #500523;
    transform: translateY(0);
  }
  
  .fileInput {
    display: none; /* Hide the default file input */
  }
  
  
  .imageUrlInput {
    width: 100%;
    font-size: 16px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    .container {
      margin-top: 40px;
    }
  }