.App {
  display: flex;
}

.toggle-menu-button {
  position: fixed;
  top: 0px;
  left: 10px;
  z-index: 1000;
  padding: 10px 20px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 99999;
}

.toggle-menu-button:hover {
  background-color: #444;
}

.sideMenuContainer {
  width: 20%;
  z-index: 9999;
}

.mainContainer {
  width: 80%;
  flex-grow: 1;
  padding: 50px 20px;
}

.signout-button {
  background-color: #f44336 !important; /* Red */
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  margin: 10px;
}

.signout-button:hover {
  background-color: #d32f2f; /* Darker red */
}
