.admin-chat-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
    font-family: Arial, sans-serif;
  }
  
  .chat-list {
    width: 80%;
    padding: 20px;
    overflow-y: auto;
  }
  
  .chat-item {
    padding: 15px;
    margin-bottom: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .chat-item:hover {
    background-color: #f0f0f0;
  }
  
  .chat-detail {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .chat-header {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #075e54;
    color: white;
  }
  
  .back-button {
    margin-right: 30px;
    padding: 10px 15px;
    background-color: white;
    color: #075e54;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 0px;
    margin-left: 20px;
  }
  
  .back-button:hover {
    background-color: #ddd;
  }
  
  .chat-messages {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
    background-color: #e5ddd5;
  }
  
  .message {
    display: flex;
    margin-bottom: 10px;
  }
  
  .sender-message {
    justify-content: flex-end; /* Align messages to the right */
  }
  
  .receiver-message {
    justify-content: flex-start; /* Align messages to the left */
  }
  
  .message-bubble {
    max-width: 70%;
    padding: 10px 15px;
    border-radius: 15px;
    font-size: 14px;
    position: relative;
  }
  
  .sender-message .message-bubble {
    background-color: #dcf8c6; /* Light green for sender */
    color: #000;
    border-radius: 15px 0 15px 15px;
    align-self: flex-end;
  }
  
  .receiver-message .message-bubble {
    background-color: #fff; /* White for receiver */
    color: #000;
    border-radius: 0 15px 15px 15px;
    align-self: flex-start;
  }
  
  .message-user-name {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
    font-size: 12px;
  }
  
  .timestamp {
    font-size: 10px;
    margin-top: 5px;
    color: #888;
    display: block;
    text-align: right;
  }
  
  
  
  .date-header {
    text-align: center;
    margin: 10px 0;
    padding: 5px 10px;
    background-color: #075e54;
    color: white;
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
  }
  
  .search-bar {
    width: 99%;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  @media (max-width: 768px) {
    .chat-detail {
      padding-top: 15px !important;
    }
  }