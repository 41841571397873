.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  z-index: 999;
}

.modal {
  background: white !important;
  position: absolute !important;
  top: 350px !important;
  left: 600px !important;
  padding: 20px;
  width: 500px !important;
  height: 150px !important;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-buttons {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 10px;
}

.modal-confirm-button,
.modal-close-button {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.modal-confirm-button {
  background-color: #28a745;
  color: white;
}

.modal-close-button {
  background-color: #dc3545;
  color: white;
}
