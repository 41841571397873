/* Add general styles for the component */
.add-services-container {
    max-width: 1000px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .add-category-button,
  .category-dropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .add-category-button {
    padding: 10px 20px;
    background-color: #500523;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .add-category-button:hover {
    background-color: #780737;
  }
  
  .category-dropdown {
    
    margin-right: 20px;
  }
  
  .category-dropdown label {
    margin-right: 10px;
    font-size: 16px;
    font-weight: bold;
  }
  
  .category-dropdown select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .services-list {
    margin-top: 20px;
  }
  
  .service-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .service-title {
    font-size: 18px;
    font-weight: bold;
    flex: 1;
  }
  
  .service-price {
    font-size: 18px;
    font-weight: bold;
    color: #500523;
    margin-right: 20px;
  }
  
  .edit-button,
  .delete-button {
    background-color: #500523;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 15px;
    margin-left: 10px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 0 !important;
  }
  
  .edit-button:hover,
  .delete-button:hover {
    background-color: #780737;
  }
  
  /* Confirmation modal styling */
  .confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    text-align: center;
  }
  
  .modal-actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
    margin-top: 20px;
  }
  
  .confirm-button,
  .cancel-button {
    padding: 10px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .confirm-button {
    background-color: #500523;
    color: #fff;
  }
  
  .cancel-button {
    background-color: #ccc;
    color: #000;
  }
  
  .confirm-button:hover {
    background-color: #780737;
  }
  
  .cancel-button:hover {
    background-color: #999;
  }
  
  .top-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.add-category-button,
.add-service-button {
  padding: 10px 20px;
  background-color: #500523;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
}

.add-category-button:hover,
.add-service-button:hover {
  background-color: #780737;
}

   