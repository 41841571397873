.faqs-admin {
    padding: 20px;
  }
  
  .faq-form input {
    margin: 5px;
    padding: 10px;
    font-size: 14px;
  }
  
  .faq-form button {
    padding: 10px 15px;
    background-color: #500523;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .faq-form button:hover {
    background-color: #760734;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    margin: 10px 0;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 20px
  }
  
  .delete-btn {
    background-color: #d9534f;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .delete-btn:hover {
    background-color: #c9302c;
  }

  .faq-form {
    margin-bottom: 40px;
  }
  
  @media (max-width: 768px) {
    .faqActions {
      flex-wrap: wrap;
    }
  }