.login-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f7f7f7;
}

.login-form {
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-form h2 {
  margin-bottom: 1.5rem;
  font-size: 2rem;
  color: #333;
}

.login-form p {
  font-size: 1rem;
  color: #888;
  margin-bottom: 1rem;
}

.form-group {
  position: relative;
  margin-bottom: 1rem;
  width: 100%;
}

.form-group label {
  display: none;
}

.form-group input {
  width: 75%;
  padding: 0.75rem 2.5rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #f0f0ff;
  font-size: 1rem;
  color: #333;
}

.form-group .icon {
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
  color: #888;
}

button {
  padding: 0.75rem 2rem;
  margin-top: 20px;
  background-color: #500523;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
}

button:hover {
  background-color: #500523aa;
}

.error {
  color: red;
  margin-bottom: 1rem;
}
