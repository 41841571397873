.users-screen {
    padding: 20px;
    margin-top: 60px;
  }
  
  .header {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 20px;
    position: sticky;
    top: 0px;
    z-index: 999;
    background-color: #fff;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 50px;
    flex-wrap: wrap;
  }
  
  .search-container {
    display: flex;
    justify-content: left;
  }
  
  .search {
    width: 300px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .search-box {
    font-size: 16px;
    border: none;
    outline: none;
    width: 100%;
  }
  
  .user-count {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-top: 10px;
  }
  
  .users-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
  }
  
  .user-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 300px;
    position: relative;
    transition: transform 0.3s ease;
    text-align: center;
    padding-bottom: 20px;
  }
  
  .user-card:hover {
    transform: translateY(-10px);
  }
  
  .profile-image {
    width: 100%;
    height: 200px;
    object-fit: contain;
  }
  
  .user-details {
    padding: 20px;
    text-align: center;
  }
  
  .user-details h2 {
    margin-bottom: 10px;
  }
  
  .user-details p {
    margin: 5px 0;
    color: #555;
    display: flex;
    align-items: center;
    justify-content: left;
  }
  
  .user-details p svg {
    margin-right: 8px;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .confirmation-modal-overlay, .edit-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .edit-modal-overlay {
    z-index: 999 !important;
  }
  
  .confirmation-modal, .edit-modal {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
  }

  .edit-modal {
    width: 400px !important;
  }
  
  .edit-modal-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
  }
  
  .edit-modal-content label {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  .edit-modal-content input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
  }
  
  .edit-modal-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .edit-modal-buttons button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    background-color: #333;
    color: #fff;
  }
  
  .edit-modal-buttons button:first-child {
    background-color: #5bc0de;
  }
  
  .confirmation-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .confirmation-buttons .button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .confirm-button {
    background: #d9534f;
    color: #fff;
  }
  
  .cancel-button {
    background: #5bc0de;
    color: #fff;
  }

  .users-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }

  .add-field-section {
    margin: 20px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    width: 25%;
  }
  
  .field-input-container label {
    display: block;
    margin: 10px 0;
  }
  
  .field-input-container input,
  .field-input-container select {
    width: 90%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .field-input-container button {
    display: block;
    margin-top: 15px;
  }
  

  /* Adjust row direction for smaller screens */
@media (max-width: 768px) {
  .users-row {
    flex-direction: column;
    align-items: center;
  }
  .user-card {
    width: 100%;
  }
}
  

.add-field-section {
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: #ffffff;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-field-section h3 {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.field-input-container label {
  display: block;
  width: 100%;
  margin-bottom: 15px;
  font-size: 14px;
  color: #555;
}

.field-input-container input,
.field-input-container select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.field-input-container input:focus,
.field-input-container select:focus {
  border-color: #007bff;
  outline: none;
}

.field-input-container button {
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.field-input-container button:hover {
  background-color: #45a049;
}

@media (max-width: 768px) {
  .add-field-section {
    width: 90%;
  }

  .field-input-container input {
  width: 90%;
}

.field-input-container select {
  width: 100%;
}

.user-count {
  margin-top: 40px;
}
}
