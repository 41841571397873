.contentContainer {
  margin-top: 60px;
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.headingContainer {
  background-color: #500523;
  border-radius: 5px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 78%;
}

.heading {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: #fff;
}

.contentContainerBlogs {
  margin-top: 60px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.contentItemVideo {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 10px;
  width: 350px;
}

.contentItemBlogs {
  border: 1px solid #ddd;
  padding: 10px 20px;
  border-radius: 10px;
}

.contentItem {
  border: 1px solid #ddd;
  padding: 20px 30px;
  border-radius: 10px;
  width: 200px;
  cursor: pointer;
}

.contentItemOpportunities {
  border: 1px solid #ddd;
  padding: 20px 30px;
  border-radius: 10px;
  width: 250px;
}

.contentItemEvents {
  border: 1px solid #ddd;
  padding: 20px 30px;
  border-radius: 10px;
  width: 250px;
}

.title {
  font-size: 18px;
  font-weight: bold;
}

.userInfo {
  display: flex;
  align-items: center;
}

.userInfoVideo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

.profileImage {
  max-width: 100px;
  width: 100%;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.profileImageBlogs {
  max-width: 300px;
  width: 100%;
  height: 200px;
  border-radius: 10px;
  margin-top: 20px;
}

.profileImageVideo {
  max-width: 50px;
  width: 100%;
  height: 50px;
  border-radius: 50%;
}

.video {
  width: 100%;
  max-width: 350px !important;
  height: auto;
}

.detail-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.detail {
  font-size: 16px;
  color: #333;
  margin-top: 0px;
}

.label {
  font-weight: 500;
}

/* ContentPage.css */
.uploadContainer {
  max-width: 600px;
  margin: 100px auto;
  padding: 30px 20px;
  background: #f0f0ff55;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.uploadForm {
  display: flex;
  flex-direction: column;
}

.uploadForm label {
  margin: 10px 0 5px;
  font-weight: bold;
}

.uploadForm input,
.uploadForm textarea {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  font-family: sans-serif;
}

.uploadForm .NewsContent {
  height: 150px;
}

.uploadForm button {
  padding: 12px 0;
  background-color: #500523;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  font-weight: 500;
  font-size: 16px;
}

.uploadForm button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

.headingContainer button {
  padding: 10px;
  margin-top: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.imageUploadContainer {
  margin-bottom: 10px;
}

.imageUploadContainer button {
  width: 100%;
  padding: 10px;
  background-color: #007bffaa;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.imagePreviewContainer .imagePreview {
  width: 100%;
  margin-top: 10px;
}

.backButton {
  padding: 10px;
  background-color: #6c757d;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupContent {
  background: white;
  width: 300px;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.popupContent p {
  margin-bottom: 20px;
}

.popupContent button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #500523;
  color: #fff;
  border-radius: 4px;
  border: none;
}

.bannersContainer {
  padding: 20px;
  margin-top: 60px;
}

.bannersList {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: start;
}

.bannerItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
  width: 300px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: relative; /* Added for positioning delete button */
}

.bannerImage {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 10px;
}

.bannerPage {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.deleteButton {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: red;
  font-size: 20px;
}

.delete-icon {
  margin-top: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: red;
  font-size: 20px;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  text-align: center;
}

.modalContent .detail-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
}

.modalContent .detail {
  margin-top: 15px;
}

.modalActions {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
}

.closeButton {
  margin-top: 20px;
  padding: 10px 20px;
  background: #500523;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.user-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.view-btn a {
  padding: 5px 15px;
  font-size: 16px;
  background: #500523;
  color: white;
  border: none;
  border-radius: 50px;
  margin-left: 6px;
  cursor: pointer;
  text-decoration: none;
}

@media (max-width: 768px) {
  .contentItem, .contentItemOpportunities, .contentItemVideo, .contentItemBlogs, .contentItemEvents {
    width: 100%;
  }
  .counts-container {
    flex-wrap: wrap;
  }

}