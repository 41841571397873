.payments-container {
  padding: 20px;
  padding-top: 0;
}

.sub-tabs {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
}

.sub-tab {
  padding: 10px 20px;
  cursor: pointer;
}

.sub-tab.active {
  border-bottom: 3px solid #333;
  font-weight: bold;
}

.sub-tab-content {
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* General Button Styles */
.button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin: 5px;
}

/* Edit Button */
.button-edit {
  background-color: #4CAF50;
  color: white;
}

/* Delete Button */
.button-delete {
  background-color: #f44336;
  color: white;
}

/* Save Button */
.button-save {
  background-color: #500523;
  color: white;
}

/* Cancel Button */
.button-cancel {
  background-color: #9E9E9E;
  color: white;
}

/* Modal Styles */
.modal-payment {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content-payment {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.modal-payment h3 {
  margin-top: 0;
}

.modal-payment select,
.modal-payment input {
  width: 100%;
  max-width: 350px;
  padding: 8px;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.modal-buttons {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.opportunity-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}

@media (max-width: 768px) {
  .sub-tabs {
   margin-top: 40px;
   flex-wrap: wrap;
   gap: 10px;
  }
  .opportunity-price {
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
  }
}